import React from "react";
import Layout from "../../components/Layout/Layout";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import LegalNav from "../../components/LegalNav/LegalNav";

const Content = () => {
  return (
    <>
      <p>
        T Cards Online respects your privacy and is committed to protecting your
        personal data. This privacy policy will inform you as to how we look
        after your personal data when you visit our website (regardless of where
        you visit it from) and tell you about your privacy rights and how the
        law protects you.
      </p>
      <p>
        This privacy policy is provided in a layered format so you can click
        through to the specific areas set out below. Please also use the
        Glossary to understand the meaning of some of the terms used in this
        privacy policy.
      </p>
      <ol>
        <li>
          <a href='#pp1'>IMPORTANT INFORMATION AND WHO WE ARE</a>
        </li>
        <li>
          <a href='#pp2'>THE DATA WE COLLECT ABOUT YOU</a>
        </li>
        <li>
          <a href='#pp3'>HOW IS YOUR PERSONAL DATA COLLECTED</a>
        </li>
        <li>
          <a href='#pp4'>HOW WE USE YOUR PERSONAL DATA</a>
        </li>
        <li>
          <a href='#pp5'>DISCLOSURES OF YOUR PERSONAL DATA</a>
        </li>
        <li>
          <a href='#pp6'>INTERNATIONAL TRANSFERS</a>
        </li>
        <li>
          <a href='#pp7'>DATA SECURITY</a>
        </li>
        <li>
          <a href='#pp8'>DATA RETENTION</a>
        </li>
        <li>
          <a href='#pp9'>YOUR LEGAL RIGHTS</a>
        </li>
        <li>
          <a href='#pp10'>GLOSSARY</a>
        </li>
      </ol>
      <p>
        <b id='pp1'>1. Important information and who we are</b>
      </p>
      <p>
        This privacy policy aims to give you information on how tcards online
        collects and processes your personal data through your use of this
        website, including any data you may provide through this website when
        you purchase a product or service.
      </p>
      <p>
        This website is not intended for children and we do not knowingly
        collect data relating to children.
      </p>
      <p>
        It is important that you read this privacy policy together with any
        other privacy policy or fair processing policy we may provide on
        specific occasions when we are collecting or processing personal data
        about you so that you are fully aware of how and why we are using your
        data. This privacy policy supplements other notices and privacy policies
        and is not intended to override them.
      </p>
      <p>
        <b>Controller</b>
      </p>
      <p>
        T Cards Direct is the controller and is responsible for your personal
        data (collectively referred to as T Cards Direct", "we", "us" or "our"
        in this privacy policy).
      </p>
      <p>
        We have appointed Sarah Richetta as our data privacy manager who is
        responsible for overseeing questions in relation to this privacy policy.
        If you have any questions about this privacy policy, including any
        requests to exercise your legal rights, please contact the data privacy
        manager using the details set out below.
      </p>
      <p>
        <b>Contact details</b>
      </p>
      <p>
        If you have any questions about this privacy policy or our privacy
        practices, please contact our data privacy manager in the following
        ways:
      </p>
      <p>
        Full name of legal entity: Registered Company.M Heine, PD Heine & SJ
        Richetta, a Partnership trading as T Cards Direct.
      </p>
      <p>
        Email address:{" "}
        <a href='mailto:support@tcardsonline.com'>support@tcardsonline.com</a>
      </p>
      <p>
        Postal address: T Cards Direct, Lillian House, Clearway, Addington,
        Kent, ME19 5BP United Kingdom.
      </p>
      <p>Telephone number: 00 44 (0)1732 871417.</p>
      <p>Our registered VAT number is GB203 8800 88</p>
      <p>
        You have the right to make a complaint at any time to the Information
        Commissioner's Office (ICO), the UK supervisory authority for data
        protection issues (www.ico.org.uk ). We would, however, appreciate the
        chance to deal with your concerns before you approach the ICO so please
        contact us in the first instance.
      </p>
      <p>
        <b>
          Changes to the privacy policy and your duty to inform us of changes
        </b>
      </p>
      <p>
        We keep our privacy policy under regular review. This version was last
        updated in August 2021.
      </p>
      <p>
        It is important that the personal data we hold about you is accurate and
        current. Please keep us informed if your personal data changes during
        your relationship with us.
      </p>
      <p>
        <b>Third-party links</b>
      </p>
      <p>
        This website may include links to third-party websites, plug-ins and
        applications. Clicking on those links or enabling those connections may
        allow third parties to collect or share data about you. We do not
        control these third-party websites and are not responsible for their
        privacy statements. When you leave our website, we encourage you to read
        the privacy policy of every website you visit.
      </p>
      <p>
        <b id='pp2'>2. The data we collect about you</b>
      </p>
      <p>
        Personal data, or personal information, means any information about an
        individual from which that person can be identified. It does not include
        data where the identity has been removed (anonymous data).
      </p>
      <p>
        We may collect, use, store and transfer different kinds of personal data
        about you which we have grouped together as follows:
      </p>
      <ul>
        <li>
          <b>Identity Data</b> includes first name, maiden name, last name,
          username or similar identifier, marital status, title, date of birth,
          gender, profession, job title.
        </li>
        <li>
          <b>Contact Data</b> includes billing address, email address and
          telephone numbers.
        </li>
        <li>
          <b>Financial Data</b> includes bank account and payment card details.
        </li>
        <li>
          <b>Transaction Data</b> includes details about payments to and from
          you and other details of products and services you have purchased from
          us.
        </li>
        <li>
          <b>Technical Data</b> collected automatically, includes internet
          protocol (IP) address, your login data, browser type and version, time
          zone setting and location, browser plug-in types and versions,
          operating system and platform, and other technology on the devices you
          use to access this website.
        </li>
        <li>
          <b>Profile Data</b> includes your username and password, purchases or
          orders made by you, your interests, preferences and feedback and
          survey responses.
        </li>
        <li>
          <b>Usage Data</b> includes information about how you use our website,
          products and services.
        </li>
        <li>
          <b>Marketing and Communications Data</b> includes your preferences in
          receiving marketing from us and our third parties and your
          communication preferences.
        </li>
      </ul>

      <p>
        We also collect, use and share <b>Aggregated Data</b> such as
        statistical or demographic data for any purpose. Aggregated Data could
        be derived from your personal data but is not considered personal data
        in law as this data will <b>not</b> directly or indirectly reveal your
        identity. For example, we may aggregate your Usage Data to calculate the
        percentage of users accessing a specific website feature. However, if we
        combine or connect Aggregated Data with your personal data so that it
        can directly or indirectly identify you, we treat the combined data as
        personal data which will be used in accordance with this privacy policy.
      </p>
      <p>
        We do not collect any <b>Special Categories</b> of Personal Data about
        you (this includes details about your race or ethnicity, religious or
        philosophical beliefs, sex life, sexual orientation, political opinions,
        trade union membership, information about your health, and genetic and
        biometric data). Nor do we collect any information about criminal
        convictions and offences.
      </p>
      <p>
        <b>If you fail to provide personal data</b>
      </p>
      <p>
        Where we need to collect personal data by law, or under the terms of a
        contract we have with you, and you fail to provide that data when
        requested, we may not be able to perform the contract we have or are
        trying to enter into with you (for example, to provide you with our
        goods and services). In this case, we may have to cancel goods or
        service you have with us but we will notify you if this is the case at
        the time.
      </p>
      <p>
        <b id='pp3'>3. How is your personal data collected?</b>
      </p>
      <p>
        We use different methods to collect data from and about you including
        through:
      </p>
      <ul>
        <li>
          <b>Direct Interactions.</b> You may give us your Identity, Contact and
          Financial Data by filling in forms or by corresponding with us by
          post, phone, email or otherwise. This includes personal data you
          provide when you:
          <ul>
            <li>contact us;</li>
            <li>apply for our products or services;</li>
            <li>create an account on our website;</li>
            <li>subscribe to our service or publications;</li>
            <li>request marketing information to be sent to you;</li>
            <li>enter a competition, promotion or survey; or</li>
            <li>give us feedback or contact us.</li>
          </ul>
        </li>
        <li>
          Identity and Contact Data relating to:
          <ul>
            <li>warranties, maintenance and guarentees;</li>
            <li>health and safety reasons, product recalls and records; and</li>
            <li>industry body documentation</li>
          </ul>
        </li>
      </ul>

      <p>
        <b id='pp4'>4. How we use your personal data</b>
      </p>
      <p>
        We will only use your personal data when the law allows us to. Most
        commonly, we will use your personal data in the following circumstances:
      </p>
      <ul>
        <li>
          Where we need to perform the contract, we are about to enter into or
          have entered into with you.
        </li>
        <li>
          Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.
        </li>
        <li>Where we need to comply with a legal obligation</li>
      </ul>
      <p>
        See GLOSSARY: LAWFUl BASIS to fonud out more about the types of lawful
        basis that we will rely on to process your personal data.
      </p>
      <p>
        Generally, we do not rely on consent as a legal basis for processing
        your personal data although we will get your consent before sending
        marketing communications to you. You have the right to withdraw consent
        to marketing at any time by contacting us.
      </p>
      <p>
        <b>Purposes for which we will use your personal data</b>
      </p>
      <p>
        We have set out below, in a table format, a description of all the ways
        we plan to use your personal data, and which of the legal bases we rely
        on to do so. We have also identified what our legitimate interests are
        where appropriate.
      </p>
      <p>
        Note that we may process your personal data for more than one lawful
        ground depending on the specific purpose for which we are using your
        data. Please contact us if you need details about the specific legal
        ground, we are relying on to process your personal data where more than
        one ground has been set out in the table below.
      </p>
      <table>
        <thead>
          <tr>
            <th>Purpose/Activity</th>
            <th>Type of data</th>
            <th>
              Lawful basis for processing including basis of legitimate interest
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>To register you as a new customer</td>
            <td>
              (a) Identity
              <br />
              (b) Contact
            </td>
            <td>Performance of a contract with you</td>
          </tr>
          <tr>
            <td>
              To process and deliver your order including:
              <br />
              (a) Manage payments, fees and charges
              <br />
              (b) Collect and recover money owed to us
            </td>
            <td>
              (a) Identity
              <br />
              (b) Contact
              <br />
              (c) Financial
              <br />
              (d) Transaction
              <br />
              (e) Marketing and Communications
            </td>
            <td>
              (a) Performance of a contract with you
              <br />
              (b) Necessary for our legitimate interests (to recover debts due
              to us)
            </td>
          </tr>
          <tr>
            <td>
              To manage our relationship with you which will include:
              <br />
              (a) Notifying you about changes to our terms or privacy policy
              <br />
              (b)Notify you of changes to T Cards Online that may affect your
              access or usage, for example updates and maintenance.
              <br />
              (c) Asking you to leave a review or take a survey
            </td>
            <td>
              (a) Identity
              <br />
              (b) Contact
              <br />
              (c) Profile
              <br />
              (d) Marketing and Communications
            </td>
            <td>
              (a) Performance of a contract with you
              <br />
              (b) Necessary to comply with a legal obligation
              <br />
              (c) Necessary for our legitimate interests (to keep our records
              updated and to study how customers use our products/services)
            </td>
          </tr>
          <tr>
            <td>
              To enable you to partake in a prize draw, competition or complete
              a survey
            </td>
            <td>
              (a) Identity
              <br />
              (b) Contact
              <br />
              (c) Profile
              <br />
              (d) Usage
              <br />
              (e) Marketing and Communications
            </td>
            <td>
              (a) Performance of a contract with you
              <br />
              (b) Necessary for our legitimate interests (to study how customers
              use our products/services, to develop them and grow our business)
            </td>
          </tr>
          <tr>
            <td>
              To administer and protect our business and this website (including
              troubleshooting, data analysis, testing, system maintenance,
              support, reporting and hosting of data)
            </td>
            <td>
              (a) Identity
              <br />
              (b) Contact
              <br />
              (c) Technical
            </td>
            <td>
              (a) Necessary for our legitimate interests (for running our
              business, provision of administration and IT services, network
              security, to prevent fraud and in the context of a business
              reorganisation or group restructuring exercise)
              <br />
              (b) Necessary to comply with a legal obligation
            </td>
          </tr>
          <tr>
            <td>
              To deliver relevant website content and advertisements to you and
              measure or understand the effectiveness of the advertising we
              serve to you
            </td>
            <td>
              (a) Identity
              <br />
              (b) Contact
              <br />
              (c) Profile
              <br />
              (d) Usage
              <br />
              (e) Marketing and Communications
              <br />
              (f) Technical
            </td>
            <td>
              Necessary for our legitimate interests (to study how customers use
              our products/services, to develop them, to grow our business and
              to inform our marketing strategy)
            </td>
          </tr>
          <tr>
            <td>
              To use data analytics to improve our website, products/services,
              marketing, customer relationships and experiences
            </td>
            <td>
              (a) Technical
              <br />
              (b) Usage
            </td>
            <td>
              Necessary for our legitimate interests (to define types of
              customers for our products and services, to keep our website
              updated and relevant, to develop our business and to inform our
              marketing strategy)
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        <b>Marketing</b>
      </p>
      <p>
        We strive to provide you with choices regarding certain personal data
        uses, particularly around marketing and advertising. We have established
        the following personal data control mechanisms:
      </p>
      <p>
        <b>Promotional offers from us</b>
      </p>
      <p>
        We may use your Identity, Contact, Technical, Usage and Profile Data to
        form a view on what we think you may want or need, or what may be of
        interest to you. This is how we decide which products, services and
        offers may be relevant for you (we call this marketing).
      </p>
      <p>
        You will receive marketing communications from us if you have requested
        information from us or purchased goods or services from us and you have
        not opted out of receiving that marketing.
      </p>
      <p>
        <b>Third-party marketing</b>
      </p>
      <p>
        We will get your express opt-in consent before we share your personal
        data with any third party for marketing purposes.
      </p>
      <p>
        <b>Opting out</b>
      </p>
      <p>
        You can ask us or third parties to stop sending you marketing messages
        by contacting us at any time.
      </p>
      <p>
        Where you opt out of receiving these marketing messages, this will not
        apply to personal data provided to us as a result of a product/service
        purchase.
      </p>
      <p>
        <b>Cookies</b>
      </p>
      <p>
        You can set your browser to refuse all or some browser cookies, or to
        alert you when websites set or access cookies. If you disable or refuse
        cookies, please note that some parts of this website may become
        inaccessible or not function properly. For more information about the
        cookies we use, please see <a href='/legal/cookies'>HERE</a>.
      </p>
      <p>
        <b>Change of purpose</b>
      </p>
      <p>
        We will only use your personal data for the purposes for which we
        collected it, unless we reasonably consider that we need to use it for
        another reason and that reason is compatible with the original purpose.
        If you wish to get an explanation as to how the processing for the new
        purpose is compatible with the original purpose, please contact us.
      </p>
      <p>
        If we need to use your personal data for an unrelated purpose, we will
        notify you and we will explain the legal basis which allows us to do so.
      </p>
      <p>
        Please note that we may process your personal data without your
        knowledge or consent, in compliance with the above rules, where this is
        required or permitted by law.
      </p>
      <p>
        <b id='pp5'>5. Disclosure of your personal data</b>
      </p>
      <p>
        We may share your personal data with the parties set out below for the
        purposes set out in the table Purposes for which we will use your
        personal data above.
      </p>
      <ul>
        <li>External Third Parties set out in the Glossary.</li>
        <li>
          Specific Third Parties listed in the table Purposes for which we will
          use your personal data above
        </li>
        <li>
          • Third parties to whom we may choose to sell, transfer or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this privacy policy. We require all third parties to
          respect the security of your personal data and to treat it in
          accordance with the law. We do not allow our third-party service
          providers to use your personal data for their own purposes and only
          permit them to process your personal data for specified purposes and
          in accordance with our instructions.
        </li>
      </ul>

      <p>
        <b id='pp6'>6. International transfers</b>
      </p>
      <p>We do not transfer your personal data outside the UK.</p>
      <p>
        <b id='pp7'>7. Data security</b>
      </p>
      <p>
        We have put in place appropriate security measures to prevent your
        personal data from being accidentally lost, used or accessed in an
        unauthorised way, altered or disclosed. In addition, we limit access to
        your personal data to those employees, agents, contractors and other
        third parties who have a business need to know. They will only process
        your personal data on our instructions and they are subject to a duty of
        confidentiality.
      </p>
      <p>
        We have put in place procedures to deal with any suspected personal data
        breach and will notify you and any applicable regulator of a breach
        where we are legally required to do so.
      </p>
      <p>
        <b id='pp8'>8. Data retention</b>
      </p>
      <p>
        We will only retain your personal data for as long as reasonably
        necessary to fulfil the purposes we collected it for, including for the
        purposes of satisfying any legal, regulatory, tax, accounting or
        reporting requirements. We may retain your personal data for a longer
        period in the event of a complaint or if we reasonably believe there is
        a prospect of litigation in respect to our relationship with you.
      </p>
      <p>
        To determine the appropriate retention period for personal data, we
        consider the amount, nature and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal, regulatory, tax, accounting or other requirements.
      </p>
      <p>
        By law we have to keep basic information about our customers (including
        Contact, Identity, Financial and Transaction Data) for six years after
        they cease being customers for tax purposes.
      </p>
      <p>
        In some circumstances you can ask us to delete your data: see your legal
        rights below for further information.
      </p>
      <p>
        In some circumstances we will anonymise your personal data (so that it
        can no longer be associated with you) for research or statistical
        purposes, in which case we may use this information indefinitely without
        further notice to you.
      </p>
      <p>
        <b id='pp9'>9. YOUR LEGAL RIGHTS</b>
      </p>
      <p>
        Under certain circumstances, you have rights under data protection laws
        in relation to your personal data. Please click on the links below to
        find out more about these rights:{" "}
      </p>
      <ul>
        <li>Request access to your personal data.</li>
        <li>Request correction of your personal data.</li>
        <li>Request erasure of your personal data.</li>
        <li>Object to processing of your personal data.</li>
        <li>Request restriction of processing your personal data.</li>
        <li>Request transfer of your personal data.</li>
        <li>Right to withdraw consent.</li>
      </ul>
      <p>
        If you wish to exercise any of the rights set out above, please contact
        us.
      </p>
      <p>
        <b>No fee usually required</b>
      </p>
      <p>
        You will not have to pay a fee to access your personal data (or to
        exercise any of the other rights). However, we may charge a reasonable
        fee, (not exceeding £25), if your request is clearly unfounded,
        repetitive or excessive. Alternatively, we could refuse to comply with
        your request in these circumstances.
      </p>
      <p>
        <b>What we may need from you</b>
      </p>
      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information
        in relation to your request to speed up our response.
      </p>
      <p>
        <b>Time limit to respond</b>
      </p>
      <p>
        We try to respond to all legitimate requests within one month.
        Occasionally it could take us longer than a month if your request is
        particularly complex or you have made a number of requests. In this
        case, we will notify you and keep you updated.
      </p>
      <p>
        <b id='pp10'>10. GLOSSARY</b>
      </p>
      <p>
        <b>Lawful basis:</b>
      </p>
      <p>
        <b>Legitimate Interest</b> means the interest of our business in
        conducting and managing our business to enable us to give you the best
        service/product and the best and most secure experience. We make sure we
        consider and balance any potential impact on you (both positive and
        negative) and your rights before we process your personal data for our
        legitimate interests. We do not use your personal data for activities
        where our interests are overridden by the impact on you (unless we have
        your consent or are otherwise required or permitted to by law). You can
        obtain further information about how we assess our legitimate interests
        against any potential impact on you in respect of specific activities by
        contacting us.
      </p>
      <p>
        <b>Performance of Contract</b> means processing your data where it is
        necessary for the performance of a contract to which you are a party or
        to take steps at your request before entering into such a contract.
      </p>
      <p>
        <b>Comply with legal obligation</b> means processing your personal data
        where it is necessary for compliance with a legal obligation that we are
        subject to.
      </p>
      <p>
        <b>Third Parties:</b>
      </p>
      <ul>
        <li>
          Professional advisers including lawyers, bankers, auditors and
          insurers who provide consultancy, banking, legal, insurance and
          accounting services.
        </li>
        <li>
          HM Revenue & Customs, regulators and other authorities acting as
          processors or joint controllers based in the United Kingdom who
          require reporting of processing activities in certain circumstances.
        </li>
      </ul>

      <p>
        <b>Your Legal Rights</b>
      </p>
      <p>You have the right to:</p>
      <p>
        <b>Request access</b> to your personal data (commonly known as a "data
        subject access request"). This enables you to receive a copy of the
        personal data we hold about you and to check that we are lawfully
        processing it.
      </p>
      <p>
        <b>Request correction</b> of the personal data that we hold about you.
        This enables you to have any incomplete or inaccurate data we hold about
        you corrected, though we may need to verify the accuracy of the new data
        you provide to us.
      </p>
      <p>
        <b>Request erasure</b> of your personal data. This enables you to ask us
        to delete or remove personal data where there is no good reason for us
        continuing to process it. You also have the right to ask us to delete or
        remove your personal data where you have successfully exercised your
        right to object to processing (see below), where we may have processed
        your information unlawfully or where we are required to erase your
        personal data to comply with local law. Note, however, that we may not
        always be able to comply with your request of erasure for specific legal
        reasons which will be notified to you, if applicable, at the time of
        your request.
      </p>
      <p>
        <b>Object to processing</b> of your personal data where we are relying
        on a legitimate interest (or those of a third party) and there is
        something about your particular situation which makes you want to object
        to processing on this ground as you feel it impacts on your fundamental
        rights and freedoms. You also have the right to object where we are
        processing your personal data for direct marketing purposes. In some
        cases, we may demonstrate that we have compelling legitimate grounds to
        process your information which override your rights and freedoms.
      </p>
      <p>
        <b>Request restriction of processing</b> of your personal data. This
        enables you to ask us to suspend the processing of your personal data in
        the following scenarios:{" "}
      </p>
      <ul>
        <li>If you want us to establish the data's accuracy.</li>
        <li>
          Where our use of the data is unlawful but you do not want us to erase
          it.
        </li>
        <li>
          Where you need us to hold the data even if we no longer require it as
          you need it to establish, exercise or defend legal claims.{" "}
        </li>
        <li>
          You have objected to our use of your data but we need to verify
          whether we have overriding legitimate grounds to use it.{" "}
        </li>
      </ul>

      <p>
        <b>Request a transfer</b> of your personal data to you or to a third
        party. We will provide to you, or a third party you have chosen, your
        personal data in a structured, commonly used, machine-readable format.
        Note that this right only applies to automated information which you
        initially provided consent for us to use or where we used the
        information to perform a contract with you.
      </p>
      <p>
        <b>Withdraw consent at any time</b> where we are relying on consent to
        process your personal data. However, this will not affect the lawfulness
        of any processing carried out before you withdraw your consent. If you
        withdraw your consent, we may not be able to provide certain products or
        services to you. We will advise you if this is the case at the time you
        withdraw your consent.
      </p>
    </>
  );
};

const PrivacyPage = () => {
  return (
    <Layout>
      <SidebarLayout
        title='Privacy Policy'
        nav={<LegalNav />}
        content={<Content />}
      />
    </Layout>
  );
};

export default PrivacyPage;
