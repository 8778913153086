import { Link } from "gatsby";
import React from "react";
import { Nav } from "react-bootstrap";
import * as styles from "./LegalNav.module.scss";

const LegalNav = () => {
  const styleProps = {
    className: styles.link,
    activeClassName: styles.active,
  };

  return (
    <Nav defaultActiveKey='/legal/privacy' className='flex-column'>
      <Nav.Link {...styleProps} to='/legal/privacy' as={Link}>
        Privacy
      </Nav.Link>
      <Nav.Link {...styleProps} to='/legal/cookies' as={Link}>
        Cookies
      </Nav.Link>
      <Nav.Link {...styleProps} to='/legal/terms-conditions' as={Link}>
        Terms & Conditions
      </Nav.Link>
      <Nav.Link {...styleProps} to='/legal/terms-of-use' as={Link}>
        Terms of Use
      </Nav.Link>
      {/* <Nav.Link {...styleProps} to='/legal/refund-policy' as={Link}>
        Refund Policy
      </Nav.Link> */}
      <Nav.Link {...styleProps} to='/legal/acceptable-use' as={Link}>
        Acceptable Use
      </Nav.Link>
    </Nav>
  );
};

export default LegalNav;
