import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import LegalNav from "../LegalNav/LegalNav";
import Masthead from "../Masthead/Masthead";
import * as styles from "./SidebarLayout.module.scss";

const SidebarLayout = ({
  title = "Legal",
  nav = <LegalNav />,
  content = <p>Please choose a page from the menu</p>,
}) => {
  return (
    <>
      <Masthead small>
        <h1>{title}</h1>
      </Masthead>
      <section className={styles.root}>
        <Container>
          <Row>
            <Col className={styles.menuButton}>
              <Button variant='primary' size='sm' href='#sub-menu'>
                Menu
              </Button>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ span: 3, order: 0 }}
              id='sub-menu'
            >
              {nav}
            </Col>
            <Col lg={9}>{content}</Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SidebarLayout;
